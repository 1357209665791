import $ from 'jquery';
import { clearErrorAndWarn, setError } from 'utils';
import { validateDate } from 'date_utils';
import anyEventsForDay from 'any_events_for_day';
import { updateDaysAndReturnDay } from 'booking_date_utils';
import { getCategory } from 'booking_category_utils';
import workingDays from 'working_days';
import validateHours from 'validate_hours';
import setWarn from 'setWarn';
import { getWorkingHours } from './working_hour_utils';
import { updateFinishForHours } from './booking_date_utils';

const SUNDAY = 0;
const SATURDAY = 6;

const validateBookingForm = target => {
  // Clear old errors first
  clearErrorAndWarn();

  formDatesValid(target);

  let validHours = true;

  const category = getCategory();
  if (category === 'project') {
    validHours = validateHours();
  }
};

const duplicateEntries = (startDate, finishDate, isVacation) => {
  var userId = $('#booking_user_id').val();
  var action = $('.new_booking')[0].action;
  var bookingId = action.split('bookings/')[1];
  if (bookingId === undefined) {
    bookingId = -1;
  }

  // If the booking has no user assigned
  if (userId == 0) {
    // Don't check for duplicate entries
    return false;
  }

  return anyEventsForDay(startDate, finishDate, userId, bookingId, isVacation);
};

const datesOutsideRange = (startDate, finishDate) => {
  if (finishDate < startDate) {
    setError('errors.booking.negative', $('.booking-alert'));
    return true;
  }

  // Owners can create and edit bookings for any dates, as long as they are not negative
  if ($('.owner')[0].innerText === 'true') {
    return false;
  }

  if (!withinAllowedDates(startDate)) {
    setError('errors.booking.outside_limits', $('.booking-alert'));
    return true;
  }

  return false;
};

const hoursOutsideRange = (startDate, hourNum) => {
  if (overMaxAvailable(startDate, hourNum, 'hours')) {
    setError('errors.booking.max_vacation_hours', $('.booking-alert'));
    return true;
  }

  return false;
};

const withinAllowedDates = startDate => {
  var yearStartDate = new Date($('.year-start')[0].innerHTML);

  if (startDate >= yearStartDate) {
    var nextVacationYearEnd = new Date(
      yearStartDate.getFullYear() + 2,
      yearStartDate.getMonth(),
      yearStartDate.getDate()
    );

    if (startDate < nextVacationYearEnd) {
      return true;
    }
  }
  return false;
};

const vacationOverLimit = (startDate, finishDate, category) => {
  var dayCount = workingDays(startDate, finishDate, false);
  if (category == 'vacation') {
    if (overMaxAvailable(startDate, dayCount[0], 'days')) {
      setError('errors.booking.max_vacation_days', $('.booking-alert'));
      return true;
    }
  }

  var dayText = dayCount[0] + ' working days';
  if (dayCount[1] > 0) {
    dayText += ' and ' + dayCount[1] + ' weekend days';
  }
  if (dayCount[2] > 0) {
    dayText += ' and ' + dayCount[2] + ' public holidays';
  }

  $('.booking-days')[0].innerText = dayText;

  checkWarnings(dayCount[0], startDate, finishDate);

  return false;
};

const overMaxAvailable = (startDate, timeToAdd, type) => {
  var userId = $('#booking_user_id').val();
  var $resourceRow = $('.fc-datagrid-body [data-resource-id=' + userId + ']');

  if (!$resourceRow[0].classList.contains(type)) {
    // Comparison is not for the same type (days / hours), skip this validation
    return false;
  }

  var vacationYearStart = new Date($('.booking-form').data().vacationYear);
  var nextVacationYear = new Date(
    vacationYearStart.getFullYear() + 1,
    vacationYearStart.getMonth(),
    vacationYearStart.getDate()
  );
  var nextVacationYearEnd = new Date(
    nextVacationYear.getFullYear() + 1,
    nextVacationYear.getMonth(),
    nextVacationYear.getDate()
  );

  var availableTime = 0;

  if (startDate > vacationYearStart) {
    if (startDate < nextVacationYear) {
      availableTime = $resourceRow.data().availableThisYear;
    } else if (startDate < nextVacationYearEnd) {
      availableTime = $resourceRow.data().availableNextYear;
    }
  } else if ($('.owner')[0].innerText === 'true') {
    // Allow owners to make vacation bookings in the past without UI validation
    // Some may still fail when they get to the server, but we don't have the
    // data to validate here
    return false;
  }

  var timeInThisBooking = $('.booking-length').attr('time');

  if (timeInThisBooking) {
    timeInThisBooking = timeInThisBooking.split(type)[0].trim();
    availableTime = parseFloat(availableTime) + parseFloat(timeInThisBooking);
  }

  if (timeToAdd > availableTime) {
    return true;
  }

  return false;
};

const checkWarnings = (vacationDays, startDate, finishDate) => {
  var $publicHolidays = $('.booking-form').data().holidays;

  if ($publicHolidays.includes(finishDate.toString().substr(4, 11))) {
    setWarn('warnings.booking.end_holiday');
  }
  if ($publicHolidays.includes(startDate.toString().substr(4, 11))) {
    setWarn('warnings.booking.start_holiday');
  }
  if (finishDate.getDay() == SATURDAY || finishDate.getDay() == SUNDAY) {
    setWarn('warnings.booking.end_weekend');
  }
  if (startDate.getDay() == SATURDAY || startDate.getDay() == SUNDAY) {
    setWarn('warnings.booking.start_weekend');
  }
  if (vacationDays == 0) {
    setWarn('warnings.booking.not_needed');
  }
};

const formDatesValid = target => {
  var startDate = validateDate('booking_start');
  if (!startDate) {
    return false;
  }

  if (target) {
    if (target.name.includes('start') || target.name.includes('hours')) {
      const bookingHours = $('#booking_hours').val();
      updateFinishForHours(bookingHours);
    }
  }

  var finishDate = validateDate('booking_finish');
  if (!finishDate) {
    return false;
  }

  //Update the day of week and return day
  updateDaysAndReturnDay(startDate, finishDate, null, target);

  const categoryName = getCategory();
  if ($.inArray(categoryName, ['project', 'vacation']) >= 0) {
    if (categoryName === 'vacation') {
      // If it's a project booking or a vacation booking, update and validate the hours selected
      const hourNum = getWorkingHours(startDate, finishDate, categoryName);
      $('#booking_hours').val(hourNum);

      if (hoursOutsideRange(startDate, hourNum)) {
        return false;
      }
    }
  }

  if (duplicateEntries(startDate, finishDate, categoryName == 'vacation')) {
    return false;
  }

  if (datesOutsideRange(startDate, finishDate)) {
    return false;
  }

  if (vacationOverLimit(startDate, finishDate, categoryName)) {
    return false;
  }

  return true;
};

export default validateBookingForm;
