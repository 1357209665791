/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'jquery/src/jquery';
import 'jquery-ujs/src/rails';
import 'webpack-jquery-ui';
import bootstrap from 'bootstrap';

import Turbolinks from 'turbolinks';
import InlineForms from '../inline_forms';
import Hamburger from '../hamburger';
import AdminPayments from '../admin_payments';
import Apps from '../apps';
import BookingFormTabs from '../booking_form_tabs';
import BookingTasks from '../booking_tasks';
import BookedTimeHelper from '../booked_time_helper';
import CalendarActions from '../calendar_actions';
import Carousel from '../carousel';
import ChoirHelper from '../choir_helper';
import CopyLink from '../copy_link';
import Configurator from '../configurator';
import CallServer from '../call_server';
import CookieSignIn from '../cookie_sign_in';
import FileHelpers from '../file_helpers';
import DialogHelper from '../dialog_helper';
import InvoiceActions from '../invoice_modal_actions';
import Payments from '../payments';
import PricingLocale from '../pricing_locale';
import Privilege from '../privilege';
import ProjectQuiz from '../project_quiz';
import ProjectCalendar from '../project_calendar';
import ProjectSelects from '../project_selects';
import ProjectTasks from '../project_tasks';
import RangeHelper from '../range_helper';
import RequiredFields from '../required_fields';
import SettingsHelper from '../settings_helper';
import TimeForm from '../time_form';
import UpdateGenerate from '../update_generate';
import ValidateAccount from '../validate_account';
import ValidateBooking from '../validate_booking';
import ValidateLocation from '../validate_location';
import ValidateItem from '../validate_item';
import ValidateOrder from '../validate_order';
import ValidatePublicHoliday from '../validate_public_holiday';
import ValidateSettings from '../validate_settings';
import ValidateSyntax from '../validate_syntax';
import ValidateUser from '../validate_user';
import ResourceCalendar from '../resource_calendar';
import FilterResults from '../filter_results';
import FilterTasks from '../filter_tasks';
import WrapDateFields from '../wrap_date_fields';

Turbolinks.start();
InlineForms();
Hamburger();
AdminPayments();
Apps();
BookingTasks();
BookingFormTabs();
BookedTimeHelper();
CalendarActions();
Carousel();
ChoirHelper();
Configurator();
CopyLink();
CallServer();
CookieSignIn();
DialogHelper();
FileHelpers();
InvoiceActions();
Payments();
PricingLocale();
Privilege();
ProjectCalendar();
ProjectQuiz();
ProjectSelects();
ProjectTasks();
RangeHelper();
RequiredFields();
SettingsHelper();
TimeForm();
UpdateGenerate();
ValidateAccount();
ValidateBooking();
ValidateLocation();
ValidateItem();
ValidateOrder();
ValidatePublicHoliday();
ValidateSyntax();
ValidateUser();
ResourceCalendar();
FilterResults();
FilterTasks();
WrapDateFields();

$(document).on('turbolinks:load', function() {
  if ($.rails) {
    $.rails.refreshCSRFTokens();
  }
  // To run on every pageload, uncomment this.
  // TODO find a solution that runs on server restart, possibly after webpacker upgrade
  // delete require.cache[require.resolve('../currency.json')]
});
