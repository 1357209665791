import $ from 'jquery';

const CalendarActions = () => {
  $(document).on('change', '.calendar-team', event => {
    calendarPath();
  });

  $(document).on(
    'click',
    '.booking-form .hide-form, .booking-form .submit-form',
    event => {
      // Reset from 0 to null to help the DB save
      if ($('#booking_user_id').val() == 0) {
        $('#booking_user_id').val(null);
      }
      $('.booking-form').dialog('close');
    }
  );

  $(document).on(
    'click',
    '.time-form .hide-form, .time-form .submit-form',
    event => {
      $('.time-form').dialog('close');
    }
  );

  $(document).on(
    'click',
    '.resource-calendar .fc-datagrid-cell-cushion',
    event => {
      var id = $(event.target.closest('td')).data('resourceId');
      var path = '/users/' + id;

      if (id == -1) {
        path = '/settings?active=holidays';
      }

      Turbolinks.visit(path);
    }
  );

  $(document).on(
    'click',
    '.edit-project .fc-datagrid-body .fc-resource',
    event => {
      var id = $(event.target.closest('.fc-datagrid-cell')).data('resourceId');
      var path = '/tasks/' + id;

      if (id == -1) {
        path = '/settings?active=holidays';
      }

      Turbolinks.visit(path);
    }
  );

  $(document).on('change', '.calendar-skill', event => {
    $('.calendar-score')
      .find('select')
      .attr('disabled', false);

    calendarPath();
  });

  $(document).on('change', '.calendar-score', event => {
    $('.calendar-team')
      .find('select')
      .val(0);

    calendarPath();
  });

  const calendarPath = () => {
    const skillId = $('.calendar-skill select').val();
    const squadId = $('.calendar-team select').val();

    var path = location.pathname + '?';
    if (skillId > 0) {
      path +=
        'skill_id=' +
        skillId +
        '&score=' +
        $('.calendar-score')
          .find('select')
          .val();
    }
    if (squadId > 0) {
      path += '&team_id=' + squadId;
    }

    Turbolinks.visit(path);
  };
};

export default CalendarActions;
