import $ from 'jquery';
import translate from 'i18n';

const eventTitle = (arg, createElement, viewType) => {
  var category = arg.event.extendedProps.category;
  var iconText = '';

  if (category == 'vacation') {
    iconText = "<i class='fas fa-umbrella-beach' role='presentation'></i>";
  } else if (category == 'ill') {
    iconText = "<i class='fas fa-stethoscope' role='presentation'></i>";
  } else if (category == 'compassionate') {
    iconText = "<i class='fas fa-heart' role='presentation'></i>";
  } else if (category == 'parental') {
    iconText = "<i class='fas fa-baby-carriage' role='presentation'></i>";
  } else if (category == 'other') {
    iconText = "<i class='fas fa-question-circle' role='presentation'></i>";
  } else if (category == 'training') {
    iconText = "<i class='fas fa-school' role='presentation'></i>";
  } else if (category == 'non-working-day') {
    iconText = "<i class='fas fa-home' role='presentation'></i>";
  } else if (category == 'project') {
    status = arg.event.extendedProps.status;

    if (status == 'blocked') {
      iconText = "<i class='fas fa-notes-medical' role='presentation'></i>";
    } else if (status == 'complete') {
      iconText = "<i class='fas fa-clipboard-check' role='presentation'></i>";
    } else if (status == 'will_not_complete') {
      iconText = "<i class='fas fa-ban' role='presentation'></i>";
    } else if (arg.event.extendedProps.statusColor == 'fade-red') {
      iconText = "<i class='fas fa-clock' role='presentation'></i>";
    }
  }

  var title = iconText + arg.event.title;
  if (viewType == 'listWeek') {
    title = '<span>' + iconText + arg.event.title + '</span>';
  }

  if (arg.event.extendedProps.desc) {
    if (viewType !== 'listWeek') {
      title += '<br/>';
    }

    title += "<span class='desc'>" + arg.event.extendedProps.desc + '</span>';
  }

  if (viewType == 'listWeek') {
    title +=
      "<span class='right'>" + arg.event.extendedProps.bookedUser + '</span>';
  }

  return { html: title };
};

export default eventTitle;
