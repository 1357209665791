import $ from 'jquery';

const WrapDateFields = () => {
  $(document).on('turbolinks:load', () => {
    $('.date-time')
      .find('select')
      .each(function(index, item) {
        if (item.disabled) {
          $(item).wrapAll('<div class="select-div disabled">');
        } else {
          if ($(item).closest('div.select-div').length === 0) {
            $(item).wrapAll('<div class="select-div">');
          }
        }
      });
  });
};

export default WrapDateFields;
