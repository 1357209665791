import $ from 'jquery';
import {
  getWorkingHourData,
  getWorkingHoursForDate,
  defaultHoursFor
} from 'working_hour_utils';
import { getWeekday } from 'date_utils';
import translate from 'i18n';

const openBookingHoursModal = (bookingId, dateClicked, hours, desc) => {
  if (!dateClicked) {
    //Set the date to today
    dateClicked = new Date().setHours(0, 0, 0, 0);
  }

  const title = translate('booking.book_hours');

  $('.time-form')
    .dialog({
      modal: true,
      show: 'show',
      width: 380,
      title: title,
      dialogClass: 'booking-dialog'
    })
    .prev()
    .find('.ui-dialog-titlebar-close')
    .hide();

  //Set the bookingId
  $('.time-form').find(
    "input#booked_time_booking_id[type='hidden']"
  )[0].value = bookingId;

  //Clear up old status
  $('.falert').hide();
  $('.warning').hide();
  $('.booking').prop('disabled', false);

  //Set the date
  $('#booked_time_date_3i').val(dateClicked.getDate());
  $('#booked_time_date_2i').val(dateClicked.getMonth() + 1);
  $('#booked_time_date_1i').val(dateClicked.getFullYear());

  $('#booked_time_finish_3i').val(dateClicked.getDate());
  $('#booked_time_finish_2i').val(dateClicked.getMonth() + 1);
  $('#booked_time_finish_1i').val(dateClicked.getFullYear());

  if ($('.day').length > 0) {
    $('.day')[0].innerText = getWeekday(dateClicked);
  }

  if (hours) {
    $('#booked_time_hours').val(hours);
  } else {
    defaultHoursFor(dateClicked);
  }

  const bookingForId = $('#booking_user_id').val();
  var currentUserId = $('.current-user')[0].innerText;

  if (bookingForId === currentUserId) {
    $('.user-warn').addClass('hidden');
  } else {
    $('.user-warn').removeClass('hidden');
    var userName =
      $('#booking_user_id')
        .find('option:selected')
        .text() || $('.booking-user-name').val();
    $('.user-for-warning').text(userName);
  }

  // Can be undefined or empty string: set it explicitly to make sure we clear out any
  // leftover contents from previous forms
  $('#booked_time_description').val(desc);
};

export default openBookingHoursModal;
