import $ from 'jquery';
import populateFormFields from 'populate_form_fields';
import translate from 'i18n';
import updateForUser from 'booking_user_utils';
import { getWorkingHours } from 'working_hour_utils';
import anyEventsForDay from 'any_events_for_day';
import { updateDaysAndReturnDay } from 'booking_date_utils';
import updateEvent from 'update_event';
import { updateForCategory } from './booking_category_utils';
import { updateTabs } from './booking_tab_utils';
import { showWorkingTab, showTimeOffTab } from './booking_form_tabs';
import invoiceProject from 'invoice_project';

const createBookingModal = (eventData, bookingForSelf) => {
  resetPage();

  // Populate the date, project, and booked by fields with the event data
  populateFormFields(eventData);
  removeReadOnly();

  var title = configureCategory(eventData, bookingForSelf);
  var category = eventData.extendedProps.category;

  if (category === undefined || category === 'project') {
    showWorkingTab();

    if (eventData.extendedProps.status === 'complete') {
      makeReadOnly();
    }
    invoiceProject(eventData.extendedProps.projectId);
  } else {
    showTimeOffTab();
  }
  updateTabs(category);

  var dialogWidth = 680;

  $('.booking-form')
    .dialog({
      modal: true,
      show: 'show',
      width: dialogWidth,
      title: title,
      dialogClass: 'booking-dialog'
    })
    .prev()
    .find('.ui-dialog-titlebar-close')
    .hide(); // Hide the standard close button;
};

const resetPage = () => {
  $('.show-form').toggleClass('disabled');

  // Tidy up any config that might be left over from looking at other events
  $('.delete').remove();
  $('.archive').remove();
  $('.new_booking')[0].action = 'bookings';
  $("input[name='_method']").remove();
  $("input[name='cal_start']").remove();
  $("input[name='team_id']").remove();
  $('.text-div').remove();
  $('.booking-details').show();
  $('.booking-status').show();
  $('#booking_id').val('');
  $('#booking_status').val('');

  $('#new_booking').trigger('reset');

  $('.return').removeClass('fade-red');
  $('.return').removeClass('fade-green');

  $('.flash').empty();
};

const removeReadOnly = () => {
  $('.select-div').removeClass('read-only');
  $('select').attr('disabled', false);
  $('textarea').attr('readonly', false);
  $('input').attr('readonly', false);
  $('.booked-times')
    .find('button')
    .attr('disabled', false);
};

const makeReadOnly = () => {
  // Owner (aka admin) can edit all fields even for "Done" bookings
  if ($('.owner')[0].innerText === 'true') {
    return null;
  }
  $('.select-div').addClass('read-only');
  $('select').attr('disabled', true);
  $('textarea').attr('readonly', true);
  $('input').attr('readonly', true);
  $('.booked-times')
    .find('button')
    .attr('disabled', true);
};

const configureCategory = (eventData, bookingForSelf) => {
  // Default title and category
  var title = translate('booking.new');
  var defaultCategory = 'project';
  var existingEventId = -1;

  var eventProps = eventData.extendedProps;

  // If editing an existing event, set the title, event id, category, and booked by fields
  if (eventData.eventId) {
    title = translate('booking.edit');
    updateEvent(eventData.eventId);
    existingEventId = eventData.eventId;

    const category = eventProps.category;

    if (category != 'project') {
      //Set category pulldown
      const absenceCategoryField = $('#booking_absence_categories');
      absenceCategoryField.val(category);
    }

    updateForCategory(category);
  }

  updateForUser();

  if (eventProps.booked_by) {
    $('.booked-by-name')[0].innerText = eventProps.booked_by;
  }

  if (eventProps.edited_by) {
    $('.edited-by-name')[0].innerText = eventProps.edited_by;
    $('.edited-by').show();
  } else {
    $('.edited-by').hide();
  }

  var start = eventData.start;
  var finish = eventData.finish;

  if ($.inArray(defaultCategory, ['project', 'vacation']) >= 0) {
    // If it's a project, or vacation booking, and the hours are not provided, calculate the working hours
    if (!$.isNumeric(eventProps.hours)) {
      const hourNum = getWorkingHours(start, finish, defaultCategory);
      $('#booking_hours').val(hourNum);
    } else {
      $('#booking_hours').val(eventProps.hours);
    }
  }

  $('.falert').hide();
  $('.warning').hide();
  $('.booking').prop('disabled', false);

  var userId = eventData.resourceIds[0];
  // Look for clashing events when creating a new vacation booking
  anyEventsForDay(
    start,
    finish,
    userId,
    existingEventId,
    defaultCategory == 'vacation'
  );

  updateDaysAndReturnDay(start, finish, eventProps.completion_date);

  return title;
};

export default createBookingModal;
