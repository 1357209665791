import $ from 'jquery';
import { setError } from 'utils';
import validateHours from 'validate_hours';
import validateBookingForm from 'validate_booking_form';

const getCategory = () => {
  let category = '';
  if ($('#working-tab').hasClass('active')) {
    category = 'project';
  } else if ($('#hours-tab').hasClass('active')) {
    category = 'project';
  } else {
    category = $('.booking-form')
      .find('#booking_absence_categories')
      .find(':selected')
      .val();
  }
  return category;
};

const updateForCategory = category => {
  //update the hidden field!
  const hiddenCategoryField = $('#booking_category');
  hiddenCategoryField.val(category);

  if (showHours(category)) {
    $('.hour-calc').show();
  } else {
    $('.hour-calc').hide();
  }

  if (category === 'project') {
    validateHours();
    $('.booking-status').show();
  } else {
    $('.booking-status').hide();
  }

  if (category === 'vacation') {
    $('#booking_hours').attr('disabled', true);
  } else {
    $('#booking_hours').attr('disabled', false);
  }

  validateBookingForm();
};

const setInvoiceInfo = invoiceValue => {
  const invoice = $('#invoice'); //Get the invoice input
  const invoice_hiddenInput = $('input[type="checkbox"]'); // Get the hidden input (this carries the value)
  const invoice_checkboxHolder = invoice.closest('.checkbox-holder'); // Get the holder parent

  if (invoiceValue) {
    // Checkbox needs to be ticked
    invoice.val(1);
    invoice.addClass('checked');
    invoice_hiddenInput.val(1);
    invoice_hiddenInput.prop('checked', true);
    invoice_checkboxHolder.addClass('checked');

    // Hide invoice warning message
    $('.invoice-banner').hide();
  } else {
    // Checkbox needs to be unticked
    invoice.val(0);
    invoice.removeClass('checked');
    invoice_hiddenInput.val(0);
    invoice_hiddenInput.prop('checked', false);
    invoice_checkboxHolder.removeClass('checked');

    // Show invoice warning message
    $('.invoice-banner').show();
  }
};

const showHours = category => {
  var userId = $('#booking_user_id').val();

  if (category == 'project') {
    // Show unassigned option
    $('#booking_user_id option[value=""]').show();
    return true;
  } else {
    // If not a project, book time off for yourself
    if (userId == null || userId == 0 || userId.length == 0) {
      userId = $('.current-user')[0].innerText;
      $('#booking_user_id').val(userId);
    }

    // Hide "unassigned" option
    $('#booking_user_id option[value=""]').hide();

    if (category == 'vacation') {
      var $resourceRow = $('td[data-resource-id=' + userId + ']');

      if ($resourceRow.hasClass('hours')) {
        return true;
      }
    }
  }

  return false;
};

export { getCategory, updateForCategory, setInvoiceInfo };
