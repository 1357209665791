import $ from 'jquery';
import { clearErrorAndWarn, setError } from './utils';
import {
  validateDate,
  getWeekday,
  nextDateExcludingWeekends
} from './date_utils';
import openBookingHoursModal from './modal_util';
import { defaultHoursFor } from './working_hour_utils';
import { createElement } from '@fullcalendar/core/preact';

const TimeForm = () => {
  $(document).on('click', '.booking-form .booked-hours .btn', event => {
    $('.time-date').show();

    const selectedDate = $('.selected-date');
    selectedDate.hide();

    var $bookedTimeForm = $('#new_booked_time')[0];
    var href = '/booked_times';
    $bookedTimeForm.action = href;
    $bookedTimeForm.method = 'post';

    timeFormContents();
  });

  $(document).on('click', '#booked_hours_rows', event => {
    const $row = $(event.target).closest('.row');
    const selectedDate = $('.selected-date');
    selectedDate[0].innerText = $row.find('.date')[0].innerText;
    selectedDate.show();

    $('.time-date').hide();
    $('.note').hide();

    var $bookedTimeForm = $('#new_booked_time')[0];

    var href = '/booked_times/' + $row.data('id');
    $bookedTimeForm.action = href;
    $bookedTimeForm.method = 'patch';

    var hours = $row.find('.hours')[0].innerText;

    var desc = $row.find('.desc')[0].innerText;

    timeFormContents(hours, desc);
  });

  $(document).on('change', '.time-form .time-date select', event => {
    // Clear old errors first
    clearErrorAndWarn();

    var updateFinish = false;

    if (
      $(event.target)
        .closest('.time-date')
        .hasClass('start')
    ) {
      updateFinish = true;
    }

    var hourDate = validateDates(updateFinish);

    if (hourDate) {
      defaultHoursFor(hourDate);
    }
  });

  $(document).on('click', '.time-form .new_booked_time .btn', event => {
    const url = event.delegateTarget.URL;
    if (
      !url.endsWith('type=listWeek') &&
      !url.endsWith('active_tab=calendar')
    ) {
      const bookingForm = $('.booking-form');
      bookingForm.show();
      bookingForm.prev().show();
    }
  });

  $(document).on('change', '.time-form #booked_time_hours', event => {
    validateHours();
  });

  // We sometimes suggest 0 hours, catch it before saving
  $(document).on('mouseenter', '.time-form input[type="submit"]', event => {
    validateHours();
  });

  // Save the new hours value to the booking display
  $(document).on('click', '.time-form input[type="submit"]', event => {
    var hours = $('#booked_time_hours').val();
    var rowId = $('#new_booked_time')[0].action.split('booked_times/')[1];
    if (rowId) {
      var $row = $('.row[data-id=' + rowId + ']');
      $row.find('.hours')[0].innerText = hours;
    }
  });
};

const validateDates = updateFinish => {
  var startDate = validateDate('booked_time_date');
  if (startDate) {
    $('.time-date.start .cwday')[0].innerText = getWeekday(startDate);
  }

  var finishDate = validateDate('booked_time_finish');
  if (finishDate) {
    $('.time-date.finish .cwday')[0].innerText = getWeekday(finishDate);
  }

  if (finishDate && startDate) {
    if (finishDate < startDate) {
      if (updateFinish) {
        $('#booked_time_finish_1i').val(startDate.getFullYear());
        $('#booked_time_finish_2i').val(startDate.getMonth() + 1);
        $('#booked_time_finish_3i').val(startDate.getDate());

        $('.time-date.finish .cwday')[0].innerText = getWeekday(startDate);

        return startDate;
      } else {
        setError('errors.booking.negative', $('.booking-alert'));
        return false;
      }
    }
    return startDate;
  }

  return false;
};

const validateHours = target => {
  // Clear old errors first
  clearErrorAndWarn();

  const hours = $('#booked_time_hours').val();
  if (hours.length === 0 || hours <= 0) {
    setError('errors.booking.time_negative', $('.booking-alert'));
  }

  return hours;
};

const timeFormContents = (hours, desc) => {
  const booking_id = $('#booking_id').val();

  var dateClicked = validateDate('booking_start');
  const status = $('#booking_status')[0].value;

  if ($('.table-body .row').length > 0) {
    const $lastRow = $('.table-body .row').last();
    var lastDate = $lastRow.find('.date')[0].innerText;
    dateClicked = new Date(lastDate);
    dateClicked.setDate(dateClicked.getDate() + 1);
    dateClicked = nextDateExcludingWeekends(dateClicked);
  }

  //Disable but still display booking form
  const bookingForm = $('.booking-form');
  bookingForm.hide();
  bookingForm.prev().hide();

  openBookingHoursModal(booking_id, dateClicked, hours, desc);

  validateDates(false);
};

export default TimeForm;
