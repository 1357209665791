import $ from 'jquery';

const getWorkingHours = (startDate, finishDate, categoryName) => {
  var hourNum = 0.0;
  var date = new Date(startDate);
  var endDate = new Date(finishDate);
  endDate.setDate(finishDate.getDate() + 1);

  var workingHoursData = getWorkingHourData(categoryName);

  // Owing to clock changes, the hours don't always match.
  date.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  while (date < endDate) {
    hourNum += getWorkingHoursForDate(workingHoursData, date);
    date.setDate(date.getDate() + 1);
  }

  hourNum = Math.round(hourNum * 10) / 10;

  return hourNum;
};

const getWorkingHoursForDate = (workingHoursData, date) => {
  // JS day starts with Sunday = 0, our data starts with Monday = 0, so need to correct
  var dayNum = date.getDay() - 1;
  if (dayNum < 0) {
    dayNum = 6;
  }

  return parseFloat(workingHoursData[dayNum]);
};

const getWorkingHourData = categoryName => {
  var userId = $('#booking_user_id').val();

  // If booked-for user is blank, the booking is unassigned, use the unassigned userid
  if (userId == null || userId == '') {
    userId = 0;
  }

  var $resourceRow = $('tr').find('[data-resource-id=' + userId + ']');
  var $userRow = $('.user-hours[data-id=' + userId + ']');

  // Calculate working hours for all time bookings, and for vacation bookings when the user is
  // working in hours but not for vacation bookings when the user is working in days
  if (categoryName === 'vacation' && $resourceRow.hasClass('days')) {
    return 0;
  }

  var workingHoursData = [];
  if ($userRow.length > 0) {
    workingHoursData = $userRow.data().hours;
  } else if ($resourceRow.length > 0 && $resourceRow.first().data().hours) {
    workingHoursData = $resourceRow.data().hours.split(',');
  } else {
    workingHoursData = $('.user-hours').data().hours;
  }

  return workingHoursData;
};

const defaultHoursFor = date => {
  var workingHours = getWorkingHourData('project');
  var hourNum = getWorkingHoursForDate(workingHours, date);
  $('#booked_time_hours').val(hourNum);
};

export {
  getWorkingHourData,
  getWorkingHoursForDate,
  getWorkingHours,
  defaultHoursFor
};
