import $ from 'jquery';
import { setError } from 'utils';

const validateHours = () => {
  var hours = $('#booking_hours').val();
  if (hours.length > 0 && hours < 0) {
    setError('errors.booking.time_negative', $('.booking-alert'));
    return false;
  }

  return true;
};

export default validateHours;
