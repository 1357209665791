import $ from 'jquery';
import translate from 'i18n';
import getBookingTasks from 'get_booking_tasks';

const ajaxCall = (method, url, data) => {
  $.ajax({
    url: url,
    method: method,
    dataType: 'json',
    data: data,
    success: function(response) {
      if (response.reload) {
        location.reload();
      }

      /*if (response.notice) {
        // TODO At the moment, good news results are swallowed unless they come with warnings or errors
        // Longer term, planning to remove them all
        // This is a reminder that for now, they are generated but not shown to the user
        // console.log(response.notice)
      }*/

      updateResults(response);

      if (response.url) {
        Turbolinks.visit(response.url);
      }

      if (response.warning) {
        showWarning(response.warning);
      }

      if (response.error) {
        showError(response.error);
      }

      /* If there's a dialog to be closed, close it */
      if (response.dialog) {
        $(response.dialog).dialog('close');

        /* If the dialog was the create task dialog, fetch the new list of tasks
           and update selection to the one we just created */
        if (response.dialog === '.dialog-create-task') {
          getBookingTasks('project_id', 0, response.id, true);
        }
      }
    },
    error: function(xhr, status, error) {
      if (xhr.responseJSON) {
        // Display the error message
        if (xhr.responseJSON['error']) {
          showError(xhr.responseJSON['error']);
        }
        // If supplied, reset previous value to the one before the failed update
        $('#' + xhr.responseJSON['field']).val(xhr.responseJSON['value']);

        // Redirect if url supplied
        if (xhr.responseJSON['url']) {
          Turbolinks.visit(xhr.responseJSON['url']);
        }
      } else if (error) {
        showError(error);
      }
    }
  });
};

const setScore = (id, score) => {
  var $spans = $('.user_skill[data-id=' + id + ']')
    .find('.user-skill')
    .find('span');
  $spans.each(function(index, span) {
    if (span.dataset.score <= score) {
      span.innerText = '★';
    } else {
      span.innerText = '☆';
    }
  });
};

const showError = errors => {
  var $dialog = $('.dialog-create-task');
  if ($dialog.length > 0) {
    var $alert = $('.create-task-alert');

    $alert[0].innerHTML = errors;
    $alert.show();
  } else {
    // Clear previous errors
    var $flash = $('.flash');
    $flash.empty();

    // Scroll to top
    window.scrollTo(0, 0);
    var $notice = document.createElement('p');
    $notice.className = 'falert';
    $notice.innerHTML = errors;
    $flash.append($notice);
    $flash.show();
  }
};

const showWarning = warning => {
  var $flash = $('.flash');
  $flash.empty();

  var $notice = document.createElement('p');
  $notice.className = 'warning';
  $notice.innerHTML = warning;
  $flash.append($notice);
  $flash.show();

  // The generate flash needs to persist between different tabs in edit page
  // Use the id so that data-turbolinks-permanent is honoured
  var $generateFlash = $('#generateflash');
  $generateFlash.empty();

  // Scroll to top
  window.scrollTo(0, 0);
  $generateFlash.append($notice);
};

const updateResults = response => {
  if (response.data_class === 'user_skill') {
    setScore(response.data.id, response.data.score);
  }

  if (response.data_class === 'account') {
    var accountId = "div[data-id='" + response.data.id + "']";
    var $accountRow = $(accountId);

    $accountRow.find('#account_status').val(response.data.status);
    $accountRow.find('.deal-expires').text(response.data.expiry);
  }

  if (response.data_class === 'user') {
    var userId = "div[data-id='" + response.data.id + "']";
    var $vacationSection = $(userId).find('.vacation-details');

    if (
      response.data.role == 'unapproved' ||
      response.data.role == 'archived'
    ) {
      $vacationSection.hide();
    } else {
      $vacationSection.show();
      $vacationSection.find('.update').prop('disabled', false);
    }
  }

  if (response.feature) {
    // Update the back button if we have prev, it might have changed
    if (response.prev) {
      $('.configurator-row.' + response.feature)
        .find('.back')
        .show()
        .data('back', response.prev);
    }

    if (response.feature === 'finish') {
      $('.configurator-form .feature-summary').show();
      const iconTrue =
        "<i class='fas fa-check' aria-label=" +
        translate('settings.features.on') +
        '></i>';
      const iconFalse =
        "<i class='fas fa-times' aria-label=" +
        translate('settings.features.off') +
        '></i>';

      $('.table-body .notes').text('');

      $.each(response.setting, function(key) {
        const value = response.setting[key];
        const selector = '.table-body .' + key + ' .select-on';
        // Clear any previous values, in case we've gone back and returned
        $(selector).empty();
        if (value) {
          $(selector).prepend(iconTrue);
        } else {
          $(selector).prepend(iconFalse);
        }

        if (key === 'invoices' && !response.setting['time']) {
          $('.table-body .' + key + ' .notes').text(
            translate('settings.invoices_disabled')
          );
        }

        if (key === 'generate' && !response.setting['skills']) {
          $('.table-body .' + key + ' .notes').text(
            translate('settings.generate_disabled')
          );
        }

        if (key === 'make_public' && !response.setting['templates']) {
          $('.table-body .' + key + ' .notes').text(
            translate('settings.make_public_disabled')
          );
        }

        if (key === 'self_service' && !response.setting['make_public']) {
          $('.table-body .' + key + ' .notes').text(
            translate('settings.self_service_disabled')
          );
        }

        // Update back button for summary page
        $('.feature-summary')
          .find('.back')
          .data('back', response.prev);
      });
    } else {
      $('.configurator-row.' + response.feature).show();
    }
  }
};

export { ajaxCall, showError, showWarning };
