import $ from 'jquery';
import { getCategory } from 'booking_category_utils';
import { shortDateWithYearAndDay, shortDateWithYear } from 'date_utils';
import { showTimeTab } from './booking_form_tabs';
import translate from 'i18n';

const bootstrap = require('bootstrap');

const updateTabs = category => {
  if (!category) {
    category = getCategory();
  }

  const owner = $('.owner')[0].innerText === 'true';
  const currentUser = $('.current-user')[0].innerText;
  const bookingFor = $('#booking_user_id').val();

  //States
  const isPreexisting = $('#booking_id').val() != '';
  const isWorking = category == 'project';
  // Can edit if currentUser = bookedFor | owner
  var userCanEdit = currentUser == bookingFor || owner;
  // Can edit if the booking has not yet been assigned
  if (bookingFor == 0 || bookingFor === null) {
    userCanEdit = true;
  }

  const workingTab = $('#working-tab');
  const workingTabObj = bootstrap.Tab.getOrCreateInstance(
    document.querySelector('#working-tab')
  );

  const notWorkingTab = $('#not-working-tab');
  var notWorkingTabObj = null;
  if (notWorkingTab.length > 0) {
    notWorkingTabObj = bootstrap.Tab.getOrCreateInstance(
      document.querySelector('#not-working-tab')
    );
  }

  const hoursTab = $('#hours-tab');
  var hoursTabObj = null;
  if (hoursTab.length > 0) {
    hoursTabObj = bootstrap.Tab.getOrCreateInstance(
      document.querySelector('#hours-tab')
    );
  }

  if (isPreexisting) {
    if (isWorking) {
      notWorkingTab.hide();
      workingTab.show();
      workingTabObj.show();
      hoursTab.show();
      hoursTabObj.show();
      showTimeTab();
    } else {
      if (notWorkingTabObj) {
        notWorkingTabObj.show();
      }
      notWorkingTab.show();
      workingTab.hide();
      hoursTab.hide();
    }
  } else {
    hoursTab.hide();
    workingTab.show();
    workingTabObj.show();
    if (userCanEdit) {
      notWorkingTab.show();
    } else {
      notWorkingTab.hide();
    }
  }
};

const loadBookingHours = booking_id => {
  // Clear old data first
  $('#booked_hours_rows').empty();

  $.ajax({
    url: '/booking_booked_times?booking_id=' + booking_id
  }).done(function(response) {
    if (response) {
      response.forEach(function(booked_time) {
        // Create row
        const rowDiv = document.createElement('div');
        rowDiv.className = 'tr row';
        rowDiv.setAttribute('data-id', booked_time.id);

        //Create date cell
        const dateDiv = document.createElement('div');
        dateDiv.className = 'col-3 col-md-2';

        const date = document.createElement('span');
        date.className = 'date';
        date.innerHTML = shortDateWithYear(new Date(booked_time.date));
        date.title = shortDateWithYearAndDay(new Date(booked_time.date));

        dateDiv.appendChild(date);
        rowDiv.appendChild(dateDiv);

        //Create user cell
        const userIdDiv = document.createElement('div');
        userIdDiv.className = 'col-6 col-md-3';

        const userId = document.createElement('span');
        userId.innerHTML = booked_time.user_name;
        userId.title = booked_time.user_name;

        userIdDiv.appendChild(userId);
        rowDiv.appendChild(userIdDiv);

        //Create hours cell
        const hoursDiv = document.createElement('div');
        hoursDiv.className = 'col-2 col-md-1';

        const hours = document.createElement('span');
        hours.className = 'hours';
        hours.innerHTML = booked_time.hours;

        hoursDiv.appendChild(hours);
        rowDiv.appendChild(hoursDiv);

        //Create last updated cell
        const invoiceIdDiv = document.createElement('div');
        invoiceIdDiv.className = 'd-none d-md-block col-md-2';

        if (booked_time.invoice_id) {
          const invoiceId = document.createElement('a');
          invoiceId.innerHTML = booked_time.invoice_id;
          invoiceId.href = 'invoices/' + booked_time.invoice_id + '/edit';
          invoiceIdDiv.appendChild(invoiceId);
        } else if (!booked_time.has_invoice) {
          const invoiceId = document.createElement('i');
          invoiceId.className = 'fas fa-exclamation-triangle';
          invoiceId.ariaLabel = translate('booking.icons.warn');
          invoiceId.title = translate('booking.icons.warn');
          invoiceIdDiv.appendChild(invoiceId);
        }

        rowDiv.appendChild(invoiceIdDiv);

        //Create description cell
        const descDiv = document.createElement('div');
        descDiv.className = 'd-none d-md-block col-md-3 desc';

        const description = document.createElement('span');

        if (booked_time.description) {
          description.innerHTML = booked_time.description;
          descDiv.appendChild(description);
        }

        rowDiv.appendChild(descDiv);
        const owner = $('.owner')[0].innerText === 'true';

        if (owner) {
          const deleteDiv = document.createElement('div');
          deleteDiv.className = 'col-1 cell';
          const deleteSpan = document.createElement('span');
          const binIcon = document.createElement('i');
          binIcon.className = 'far fa-trash-alt';
          var deleteAction = document.createElement('a');
          deleteAction.className = 'icon destroy';
          deleteAction.rel = 'nofollow';
          deleteAction.method = 'delete';
          deleteAction.setAttribute('data-method', 'delete');
          deleteAction.href = '/booked_times/' + booked_time.id;
          deleteAction.appendChild(binIcon);
          deleteSpan.appendChild(deleteAction);
          deleteDiv.appendChild(deleteSpan);
          rowDiv.appendChild(deleteDiv);
        }

        document.getElementById('booked_hours_rows').appendChild(rowDiv);
      });
    }
  });
};

export { updateTabs, loadBookingHours };
